import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import IntroTitle from "../../../components/wcc/IntroTitle"
import AppSelector from "../../../components/wcc/AppSelector"
import WCCIntro from "../../../components/wcc/WCCIntro"
import AppStoreLinks from "../../../components/AppStoreLinks"

const WCCDhagaPage = () => {
    return (
        <Layout bgClass="bg-black" fgMode="light">
          <Seo 
            title="Dhaga"
            description="Geomapping meets ancient country"
          />
          <IntroTitle />
          <AppSelector />
          <div className="w-full pt-8 pb-16 text-white bg-black">
            <div className="grid max-w-screen-lg grid-cols-[1fr,1.2fr,1fr] gap-4 mx-auto">
              <div className="pt-16 pl-6">
                <h2 className="mb-4 text-2xl font-bold">A vision for education</h2>
                <p>Yalbilinya uses computer Vision technology built into modern phones to highlight areas of the user's face.</p>
              </div>
              <div className="flex items-center justify-center">
                <StaticImage
                  height={730}
                  width={363}
                  alt="Sticker Christmas"
                  src="../../../images/iphone-wcc-dictionary.png" 
                  placeholder="blurred"/>
              </div>
              <div className="pt-16 pr-6">
                <h2 className="mb-4 text-2xl font-bold">Game like experiences</h2>
                <p></p>
    
              </div>
    
            </div>
          </div>
          <AppStoreLinks 
            iOSStoreLink="https://itunes.apple.com/us/app/monstapals/id1313615216?mt=8&at=1l3v8JX"
            googlePlayStoreLink="https://play.google.com/store/apps/details?id=au.net.anomaly.Chimera"/>

          <WCCIntro/>
        </Layout>
     );
};
export default WCCDhagaPage;